<template>
	<div style="height: 100%;">
		<template v-if="theme.is_mobile && theme.width < 750">
			<div class="head">
				<div>{{params.page}} / {{Math.ceil(params.total/params.perPage)}}，共 {{params.total}} 条记录</div>
				<div @click="popup_filter = true">筛选</div>
			</div>
			<div class="wrap">
				<div style="margin-bottom: 10px; padding: 10px; background-color: #fff;" v-for="ticket in clues" :key="ticket.id">
					<div>{{ticket.no}}</div>
					<div v-if="admin_user.shop_id">{{ticket | format_department}}</div>
					<div v-if="ticket.created_at">{{ticket.created_at}}</div>
				</div>
			</div>
			<van-pagination v-model="params.page" :total-items="params.total" :items-per-page="params.perPage" @change="(v) => { getContractsFees({ ...params, page: v }) }"></van-pagination>
		</template>
		<template v-else>
			<main-table>
				<el-form slot="header" :inline="true" :model="params" :size="theme.size">
					<el-form-item>
						<el-input v-model="params.keywords" placeholder="名称/手机/邮箱/社交账号" clearable></el-input>
					</el-form-item>
					<el-form-item v-if="admin_user.shop_id">
						<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item v-else>
						<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.employee_id" placeholder="请选择业务" filterable  multiple collapse-tags>
							<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-cascader style="width: 100%;" placeholder="归属地区" v-model="params.region_id" :options="tree_regions" :props="{checkStrictly: true, expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.type" placeholder="请选择社交类型" style="width: 100%;" filterable multiple collapse-tags>
							<el-option v-for="(type, t) in social_types" :key="t" :label="type" :value="t"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.source" placeholder="请选择来源" style="width: 100%;" filterable multiple collapse-tags>
							<el-option v-for="(source, s) in sources" :key="s" :label="source" :value="+s"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable>
							<el-option label="创建时间" value="created"></el-option>
							<el-option label="更新时间" value="updated"></el-option>
						</el-select>
						<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getClues({ ...params, page: 1, unbuyer: 0})">查询</el-button>
						<el-button type="primary" @click="getClues({ ...params, page: 1, unbuyer: 1})">待分配</el-button>
					</el-form-item>
				</el-form>
				<el-table slot="table" ref="clues_table" class="scroll-wrapper absolute" height="100%" :data="tables" :size="theme.size">
					<el-table-column type="selection" width="42"></el-table-column>
					<el-table-column label="站点" width="100" v-if="!admin_user.shop_id" :formatter="(r) => { return r.shop ? r.shop.name : theme.group; }"></el-table-column>
					<el-table-column label="名称" prop="name">
						<el-link slot-scope="scope" type="primary" @click="clue = {...scope.row, tags: scope.row.tags.map(({name}) => { return name; } )}; create_dialog = true;" :disabled="!$utils.update($api.URI_CLUES)">{{scope.row.name}}</el-link>
					</el-table-column>
					<el-table-column label="联系方式" min-width="140">
						<template slot-scope="scope">
							<p v-if="scope.row.mobile">手机 {{scope.row.mobile}}</p>
							<p v-if="scope.row.email">邮箱 {{scope.row.email}}</p>
							<p v-if="scope.row.social_account">{{social_types[scope.row.social_type]}} {{scope.row.social_account}}</p>
							<p v-if="scope.row.region">{{scope.row.region.name}}</p>
							<p v-if="scope.row.address">{{scope.row.address}}</p>
							<p>
								<el-tag size="mini" style="margin-right: 10px;" v-for="tag in scope.row.tags" :key="tag.id">{{tag.name}}</el-tag>
							</p>
						</template>
					</el-table-column>
					<el-table-column label="渠道来源" width="100" :formatter="(r) => { return sources[r.source] || '未知'; }"></el-table-column>
					<el-table-column label="录入专员" width="100" :formatter="(r) => { return r.employee ? r.employee.name : '系统'; }"></el-table-column>
					<el-table-column label="跟进情况" min-width="120">
						<template slot-scope="scope">
							<template v-if="scope.row.client">
								<p>
									<el-link type="primary" @click="$refs['show_client'].open(scope.row.client.id)"><i class="el-icon-user"></i> {{scope.row.client.name}}</el-link>
								</p>
								<p v-if="scope.row.client.employee"><i class="el-icon-suitcase"></i> {{scope.row.client.employee.name}}</p>
								<p v-else>已流入客户公海</p>
							</template>
							<p v-else>未分配</p>
						</template>
					</el-table-column>
					<el-table-column label="备注说明" prop="remarks" min-width="200"></el-table-column>
					<el-table-column label="联系方式" min-width="200" v-if="theme.width >= 1440">
						<template slot-scope="scope">
							<p v-if="scope.row.updated_at">更新 {{scope.row.updated_at}}</p>
							<p v-if="scope.row.created_at">创建 {{scope.row.created_at}}</p>
						</template>
					</el-table-column>
					<el-table-column width="60">
						<el-dropdown slot="header" @command="batchAction">
							<el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="create" :disabled="!$utils.create('clients')">添加线索</el-dropdown-item>
								<!-- 之前分公司模式下，才能分配线索，防止分配到其它公司 -->
								<el-dropdown-item command="assign" :disabled="!$utils.create('clients')" v-if="admin_user.shop_id">分配线索</el-dropdown-item>
								<el-dropdown-item command="export">导出选中</el-dropdown-item>
								<el-dropdown-item command="delete">删除选中</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<el-button slot-scope="scope" type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_CLUES) || scope.row.deleted_at">删除</el-button>
					</el-table-column>
				</el-table>
				<el-pagination
					slot="footer"
					:layout="theme.is_mobile ? 'total, prev, pager, next' : $config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					@size-change="(v) => { getClues({ ...params, page: 1, perPage: v }) }"
					@current-change="(v) => { getClues({ ...params, page: v }) }"
					background>
				</el-pagination>
			</main-table>
			<el-dialog width="420px" :title="clue.id ? '编辑线索' : '添加线索'" :visible.sync="create_dialog" :fullscreen="theme.width < 420" :before-close="closeImporting" append-to-body>
				<div v-if="importing">
					<h3>正在导入线索 {{import_index}}/{{import_count}}</h3>
					<el-progress :percentage="Math.round((import_index/import_count)*100)"></el-progress>
				</div>
				<div style="max-height: 64vh; overflow: auto; padding-right: 10px;" class="scroll-wrapper" v-else>
					<el-form ref="create_form" label-width="80px" :model="clue" :rules="clue_rules" :size="theme.size" status-icon>
						<el-form-item label="线索名称" prop="name">
							<el-input v-model="clue.name" autocomplete="off" placeholder="请输入线索名称" clearable>
								<el-select slot="prepend" style="width: 100px;" v-if="!admin_user.shop_id" v-model="clue.shop_id" placeholder="城市站点" filterable clearable>
									<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
								</el-select>
								<el-radio slot="append" v-model="clue.is_client" v-if="!clue.id" label="1">创建客户</el-radio>
							</el-input>
						</el-form-item>
						<el-form-item label="手机号码" prop="mobile">
							<el-input v-model="clue.mobile" autocomplete="off" placeholder="请输入手机号码" clearable></el-input>
						</el-form-item>
						<el-form-item label="电子邮箱" prop="email">
							<el-input v-model="clue.email" autocomplete="off" placeholder="请输入电子邮箱" clearable></el-input>
						</el-form-item>
						<el-form-item label="社交账号" prop="social_account">
							<el-input v-model="clue.social_account" autocomplete="off" placeholder="请输入社交账号" clearable>
								<el-select slot="prepend" style="width: 100px;" v-model="clue.social_type" placeholder="账号类型" filterable clearable>
									<el-option v-for="(type, t) in social_types" :key="t" :label="type" :value="t"></el-option>
								</el-select>
							</el-input>
						</el-form-item>
						<el-form-item label="渠道来源" prop="source">
							<el-select v-model="clue.source" placeholder="请选择渠道来源" style="width: 100%;" :clearable="!clue.id" filterable>
								<el-option v-for="(source, s) in sources" :key="s" :label="source" :value="+s"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="标签类型" prop="region_id">
							<el-select style="width: 100%;" placeholder="请选择标签类型" v-model="clue.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable allow-create default-first-option>
								<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="所属地区" prop="region_id">
							<el-cascader style="width: 100%;" placeholder="请选择归属地区" v-model="clue.region_id" :options="tree_regions" :props="{checkStrictly: true, expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
						</el-form-item>
						<el-form-item label="详细地址" prop="address">
							<el-input v-model="clue.address" autocomplete="off" placeholder="请输入详细地址" clearable></el-input>
						</el-form-item>
						<el-form-item label="备注说明" prop="remarks">
							<el-input v-model="clue.remarks" type="textarea" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button slot="label" type="text" @click="downloadTemp" v-if="!clue.id">下载模板</el-button>
							<el-button type="primary" @click="submitForm('create_form')">提交保存</el-button>
							<el-upload style="display: inline-block; margin: 0 10px" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importInventories" :action="$api.URI_UPLOADS" :disabled="!$utils.create($api.URI_CLUES)" v-if="!clue.id">
								<el-button type="primary" :disabled="!$utils.create($api.URI_CLUES)" plain>导入线索</el-button>
							</el-upload>
						</el-form-item>
					</el-form>
				</div>
			</el-dialog>
			<el-dialog width="360px" title="分配线索" :visible.sync="distribute_dialog" :fullscreen="theme.width < 360" :before-close="closeAssigning" append-to-body>
				<div v-if="assigning && distribute.selection">
					<h3>正在分配线索 {{assign_current}}/{{distribute.selection.length}}</h3>
					<el-progress :percentage="Math.round((assign_current/distribute.selection.length)*100)"></el-progress>
				</div>
				<el-form ref="distribute_form" label-width="80px" :model="distribute" :size="theme.size" v-else status-icon>
					<el-form-item label="分配数量" v-if="distribute.selection">{{distribute.selection.length}} 条线索</el-form-item>
					<el-form-item label="业务专员" prop="employee_id">
						<el-select v-model="distribute.employee_id" placeholder="请选择业务专员" style="width: 100%;" filterable clearable>
							<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('distribute_form')">提交分配</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<show-client ref="show_client"></show-client>
		</template>
	</div>
</template>

<style>
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';
	import mainTable from '@/components/main-table';
	import showClient from './clients/show';

	const mobile_reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
	const email_reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	export default {
		components: {
			showClient,
			mainTable
		},
		computed: {
			...mapState(['theme', 'regions', 'admin_user', 'oa_users', 'oa_departments', 'clues']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			social_types () {
				return this.clues.social_types || [];
			},
			sources () {
				return this.clues.sources || [];
			},
			table_temp () {
				const thead = ['名称', '来源ID', '手机', '邮箱', '社交账户', '社交账户类型', '地区ID', '地址', '备注', '标签', '创建客户'];
				const filter = ['name', 'source', 'mobile', 'email', 'social_account', 'social_type', 'region_id', 'address', 'remarks', 'tags', 'is_client'];
				if (!this.admin_user.shop_id) {
					thead.unshift('站点ID');
					filter.unshift('shop_id');
				}
				return {
					data: [],
					sheet: '线索列表',
					thead,
					filter
				};
			}
		},
		methods: {
			async remoteMethod (query) {
				if (query !== '') {
					this.tags_loading = true;
					const res = await this.$http.get(this.$api.URI_TAGS, { params: {action: 'search', word: query} });
					this.tags = res.data.result.map((tag) => { return tag.name; });
					this.tags_loading = false;
				} else {
					this.tags = [];
				}
			},
			closeImporting (done) {
				if (!this.importing) return done();
				this.$confirm('是否中断导入操作？').then(() => {
					this.importing = false;
					done();
				});
			},
			closeAssigning (done) {
				if (!this.assigning) return done();
				this.$confirm('是否中断分配操作？').then(() => {
					this.assigning = false;
					done();
				});
			},
			closeImported (data) {
				const { thead, filter } = this.table_temp;
				this.importing = false;
				this.create_dialog = false;
				this.import_index = 0;
				this.import_count = 0;
				this.getClues(this.params);
				this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "线索导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
			},
			closeAssigned () {
				this.assigning = false;
				this.distribute_dialog = false;
				this.assign_current = 0;
				this.getClues(this.params);
			},
			onDelete (r) {
				this.$confirm(r.name, '确定要删除线索吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CLUES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getClues(this.params);
					this.$message.success(msg);
				})
			},
			async getClues (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CLUES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.shops = result.shops;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async submitClue (data) {
				const res = await this.$http.post(this.$api.URI_CLUES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getClues(this.params);
						this.create_dialog = false;
					}
				});
			},
			async submitClient (data) {
				const res = await this.$http.post(this.$api.URI_CLIENTS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'create_form':
							if (!this.clue.email && !this.clue.social_account && !this.clue.mobile) return this.$message.error("手机/邮箱/社交账号必填一个");
							this.submitClue(this.clue);
						break;
						case 'distribute_form':
							var clues = this.distribute.selection || [];
							if (!clues.length) return this.$message.error("请选择分配线索");
							this.assigning = true;
							for (let c in clues) {
								if (!this.assigning) {
									this.closeAssigned();
									break;
								}
								this.assign_current++;
								if (!clues[c].name) {
									this.$message.error("未知线索名称");
									continue;
								}
								if (!clues[c].mobile && !clues[c].email && !clues[c].social_account) {
									this.$message.error("未知线索联系方式");
									continue;
								}
								await this.submitClient({source_model_type: 'App\\Models\\Clue', source_model_id: clues[c].id, employee_id: this.distribute.employee_id});
							}
							if (this.assign_current === clues.length) {
								setTimeout(() => {
									this.closeAssigned();
								}, 1000);
							}
						break;
					}
				});
			},
			importInventories ({raw}) {
				this.importing = true;
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				const social_types = [];
				for (let id in this.social_types) {
					social_types.push(id);
				}
				fileReader.onload = async ({target}) => {
					try {
						const { filter } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						if (data.length <= 0) {
							this.importing = false;
							return this.$message.error("无需要导入记录！");
						}
						this.import_count = data.length;
						for (var i in data) {
							if (!this.importing) {
								this.closeImported(data);
								break;
							}
							const { name, mobile, email, social_type, social_account, region_id, tags } = data[i];
							this.import_index++;
							if (!name) {
								data[i].status = "提交名称不能为空";
								continue;
							}
							if (!mobile && !email && !social_account) {
								data[i].status = "手机/邮箱/社交账号必填其中一项";
								continue;
							}
							if (social_type && social_types.indexOf(social_type) < 0) {
								data[i].status = "社交账号类型不存在";
								continue;
							}
							// if (mobile && !mobile_reg.test(mobile)) {
							// 	data[i].status = "手机格式不正确";
							// 	continue;
							// }
							// if (email && !email_reg.test(email)) {
							// 	data[i].status = "邮箱格式不正确";
							// 	continue;
							// }
							if (region_id && region_id < 0) {
								data[i].status = "提交地区ID必须大于0，请参考地区Sheets";
								continue;
							}
							const res = await this.$http.post(this.$api.URI_CLUES, {...data[i], tags: tags ? tags.replace("，", ",").split(",") : null});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (this.import_index === this.import_count) {
							setTimeout(() => {
								this.closeImported(data);
							}, 1000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				const sources = [];
				for (let id in this.sources) {
					sources.push({id, name: this.sources[id]});
				}
				const social_types = [];
				for (let id in this.social_types) {
					social_types.push({id, name: this.social_types[id]});
				}
				const regions = {};
				this.regions.forEach((r) => {
					regions[r.id] = r.name;
					r.parent_name = regions[r.parent_id]; 
				});
				const template = [
					{
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '社交账户类型',
						data: social_types
					}, {
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '来源ID',
						data: sources
					}, {
						filter: ['id', 'name', 'parent_name'],
						thead: ['ID', '名称', '上级'],
						sheet: '地区ID',
						data: this.regions
					}
				];
				if (!this.admin_user.shop_id) {
					template.push({
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '站点ID',
						data: this.shops
					});
				}
				template.unshift(this.table_temp);
				ad_export_json_to_excel_multi_sheet(template, '线索导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			},
			batchAction (m) {
				if (m === 'create') {
					this.create_dialog = true;
					this.clue = {};
					return;
				}
				const { selection } = this.$refs.clues_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				switch (m) {
					case 'assign':
						for (let clue of selection) {
							if (clue.client) return this.$message.error('选中 '+clue.name+' 线索已分配！');
						}
						this.distribute_dialog = true;
						this.distribute = { ...this.distribute, selection };
						break;

					case 'export':
							selection.forEach((c) => {
								c.f_source = this.sources[c.source];
								c.f_social_type = this.social_types[c.social_type];
							});
							this.$confirm(`此操作将导出${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								var file = "线索导出" + this.$moment().format('YYYYMMDDHHmmss');
								var header = [['ID', '名称', '来源', '手机', '邮箱', '社交账户', '社交账户类型', '备注', '更新时间', '创建时间']];
								var filter = ['id', 'name', 'f_source', 'mobile', 'email', 'social_account', 'f_social_type', 'remarks', 'updated_at', 'created_at'];
								this.$utils.handleDownload(header, filter, selection, file, "线索列表");
							});
						break;
						
					case 'delete':
							this.$confirm(`此操作将永久删除${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(async () => {
								let index = 0;
								let notify = this.$notify({
									title: '提示',
									dangerouslyUseHTMLString: true,
									message: '<p>正在准备删除...</p>',
									showClose: false,
									duration: 0
								});
								for (var o in selection) {
									let { id } = selection[o];
									index += 1;
									notify.message = '<p>正在删除 ' + index + ' / ' + selection.length + '</p>';
									await this.$http.get(this.$api.URI_CLUES, {params: {action: "delete", id}, headers: {loading: true}});
								}
								if (index === selection.length) {
									notify.message = '<p>删除完成！</p>';
									setTimeout(() => {
										notify.close();
										this.getClues(this.params);
									}, 3000);
								}
							});
						break;

					default:
					this.$message.error('请选择操作内容');
					break;
				}
			}
		},
		data() {
			const checkEmail = (rule, value, callback) => {
				if (value && !email_reg.test(value)) return callback(new Error('请输入正确的电子邮箱'));
				return callback();
			}
			const checkMobile = (rule, value, callback) => {
				if (value && !mobile_reg.test(value)) return callback(new Error('请输入正确的手机号'));
				return callback();
			}
			return {
				tags: [],
				shops: [],
				tables: [],
				employees: [],
				clue: {},
				distribute: {},
				tags_loading: false,
				create_dialog: false,
				distribute_dialog: false,
				importing: false,
				assigning: false,
				import_index: 0,
				import_count: 0,
				assign_current: 0,
				params: {
					perPage: 10,
					keywords: '',
				},
				clue_rules: {
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
					email: [{ validator: checkEmail, trigger: ['blur', 'change'] }],
					mobile: [{ validator: checkMobile, trigger: ['blur', 'change'] }],
					source: [{ required: true, message: '请选择来源', trigger: 'change' }]
				}
			}
		},
		async created () {
			this.getClues(this.params, true);
		}
	};
</script>